export const businessUserlCol = [
  {
    label: "名称",
    prop: 'username',
    align: 'center',
  },
  {
    label: "手机号码",
    prop: 'mobile',
    align: 'center',
  },
  {
    label: "创建时间",
    prop: 'create_time',
    align: 'center',
  },
  {
    label: "修改时间",
    prop: 'update_time',
    align: 'center',
  },
  {
    label: "角色",
    prop: 'role_name',
    align: 'center',
  },
  {
    label: "状态",
    prop: 'states_text',
    align: 'center',
  },
  {
    label: "一级机构",
    prop: 'senior_name',
    align: 'center',
  },
  {
    label: "主体",
    prop: 'entity_name',
    align: 'center',
  },
  {
    label: "商家",
    prop: 'business_name',
    align: 'center',
  },
  {
    label: "操作",
    slot: 'operation'
  },
]
export default {
  businessUserlCol,
}