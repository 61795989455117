<template>
  <div class="businessAdminList">
    <div class="search-bar">
      <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="100px"
        size="small"
      >
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="一级机构">
              <el-select
                v-model="searchForm.senior_id"
                :remote-method="seniorRemoteMethod"
                :loading="seniorLoading"
                placeholder="请输入关键词"
                style="width: 100%"
                clearable
                filterable
                remote
                @clear="onSeniorClear"
                @change="serchData"
              >
                <el-option
                  v-for="item in seniorList"
                  :key="item.senior_id"
                  :label="item.senior_name"
                  :value="item.senior_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="主体名称">
              <el-select
                v-model="searchForm.entity_id"
                style="width: 100%"
                :remote-method="entityRemoteMethod"
                :loading="entityLoading"
                placeholder="请输入关键词"
                filterable
                remote
                clearable
                @clear="onEntityClear"
                @change="serchData"
              >
                <el-option
                  v-for="item in entityList"
                  :key="item.entity_id"
                  :label="item.entity_name"
                  :value="item.entity_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="商家名称">
              <el-select
                style="width: 100%"
                v-model="searchForm.business_id"
                :remote-method="businessRemoteMethod"
                :loading="businessLoading"
                placeholder="请输入关键词"
                filterable
                remote
                clearable
                @clear="onBusinessClear"
                @change="serchData"
              >
                <el-option
                  v-for="item in businessList"
                  :key="item.business_id"
                  :label="item.full_name"
                  :value="item.business_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="账户状态">
              <el-select
                v-model="searchForm.status"
                placeholder="请选择"
                style="width: 100%"
                clearable
                @change="serchData"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="名称：">
              <el-input
                v-model="searchForm.username"
                placeholder="请输入名称"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号：">
              <el-input
                v-model="searchForm.mobile"
                placeholder="请输入手机号"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="角色：">
              <el-select
                style="width: 100%"
                v-model="searchForm.role_id"
                filterable
                clearable
                placeholder="请选择角色"
              >
                <el-option
                  v-for="item in roleList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <div class="inben" style="margin-left: 10px">
            <el-button type="primary" size="small" @click="serchData"
              >搜索</el-button
            >
          </div>
        </el-row>
      </el-form>
    </div>
    <div class="derive">
      <el-button
        size="small"
        style="float: right; margin-bottom: 20px"
        type="primary"
        v-if="managerType != 0 || getAuth('businessAdminOperate')"
        @click="addUser()"
        >新增用户</el-button
      >
    </div>
    <base-table
      :columns="businessUserlCol"
      :data="tableData"
      :pagination="searchForm"
      :total="total"
      @getData="initData"
      :loading="loading"
      :isPaginationShow="true"
      wrapperHeight="calc(100% - 45px)"
    >
      <el-table-column
        slot="operation"
        label="操作"
        align="center"
        width="180"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            v-if="
              (managerType != 0 || getAuth('businessAdminOperate')) &&
              scope.row.status != 0
            "
            @click="amend(scope.row)"
            >修改</el-button
          >
          <el-button
            type="text"
            size="small"
            v-if="
              (managerType != 0 || getAuth('businessAdminOperate')) &&
              scope.row.status != 0
            "
            @click="delAdmin(scope.row)"
            >删除</el-button
          >
          <el-button
            type="text"
            size="small"
            v-if="
              (managerType != 0 || getAuth('businessAdminOperate')) &&
              scope.row.status == 0
            "
            @click="recoverAdmin(scope.row)"
            >恢复</el-button
          >
        </template>
      </el-table-column>
    </base-table>
    <business-admin-modal ref="businessAdminModal" />
  </div>
</template>

<script>
import columns from "./columns";
import {
  listBusinessAdmin,
  businessStatusRoleList,
  statusBusinessAdmin,
} from "@/api/businessManage.js";
import businessAdminModal from "./components/business-admin-modal.vue";
import { selectBusiness, selectSenior, selectEntity } from "@/api/selectfun";
import { getAuth } from "@/utils/index.js";
export default {
  components: { businessAdminModal },
  name: "businessAdminList",

  data() {
    return {
      managerType: localStorage.getItem("managerType"),
      getAuth: getAuth,
      ...columns,
      tableData: [],
      total: 0,
      loading: false,
      searchForm: {
        page: 1,
        per_page: 10,
        status: 1,
      },
      roleList: [],
      seniorList: [],
      seniorLoading: false,
      entityList: [],
      entityLoading: false,
      businessList: [],
      businessLoading: false,
      statusList: [
        {
          value: 1,
          label: "正常",
        },
        {
          value: 2,
          label: "禁用",
        },
        {
          value: 0,
          label: "删除",
        },
      ],
    };
  },

  mounted() {
    this.initData();
    this.getAllRoleList();
  },

  methods: {
    serchData(){
      this.searchForm.page = 1
      this.searchForm.per_page = 10
      this.initData()
    },
    async initData() {
      const { code, data } = await listBusinessAdmin(this.searchForm);
      if (code == 200) {
        this.tableData = data.data.map((item) => {
          item.states_text =
            item.status == 1 ? "正常" : item.status == 2 ? "禁用" : "删除";
          return item;
        });
        this.total = data.total;
      }
    },
    addUser() {
      this.$refs.businessAdminModal.isShow("add");
    },
    amend(row) {
      this.$refs.businessAdminModal.isShow("edit", row);
    },
    delAdmin(row) {
      let that = this;
      this.$confirm("是否删除当前账号？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const params = {
            manager_id: row.id,
            status: 0,
          };
          const { code } = await statusBusinessAdmin(params);
          if (code == 200) {
            that.$message({
              type: "success",
              message: "删除成功!",
            });
            that.initData();
          }
        })
        .catch(() => {});
    },
    recoverAdmin(row) {
      let that = this;
      this.$confirm("是否恢复当前账号？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const params = {
            manager_id: row.id,
            status: 1,
          };
          const { code } = await statusBusinessAdmin(params);
          if (code == 200) {
            that.$message({
              type: "success",
              message: "操作成功!",
            });
            that.initData();
          }
        })
        .catch(() => {});
    },
    async getSenior(query) {
      const param = {
        senior_name: query,
      };
      const { code, data } = await selectSenior(param);
      if (code == 200) {
        this.seniorList = data;
      }
    },
    onSeniorClear() {
      this.searchForm.senior_id = "";
      this.seniorList = [];
    },
    seniorRemoteMethod(query) {
      if (query != "") {
        this.seniorLoading = true;
        setTimeout(() => {
          this.seniorLoading = false;
          this.getSenior(query);
        }, 200);
      } else {
        this.seniorList = [];
      }
    },
    async getEntity(query) {
      const param = {
        entity_name: query,
      };
      const { code, data } = await selectEntity(param);
      if (code == 200) {
        this.entityList = data;
      }
    },
    onEntityClear() {
      this.searchForm.entity_id = "";
      this.entityList = [];
    },
    entityRemoteMethod(query) {
      if (query != "") {
        this.entityLoading = true;
        setTimeout(() => {
          this.entityLoading = false;
          this.getEntity(query);
        }, 200);
      } else {
        this.entityList = [];
      }
    },
    async getBusiness(query) {
      const param = {
        business_name: query,
      };
      const { code, data } = await selectBusiness(param);
      if (code == 200) {
        this.businessList = data;
      }
    },
    onBusinessClear() {
      this.searchForm.business_id = "";
      this.businessList = [];
    },
    businessRemoteMethod(query) {
      if (query != "") {
        this.businessLoading = true;
        setTimeout(() => {
          this.businessLoading = false;
          this.getBusiness(query);
        }, 200);
      } else {
        this.businessList = [];
      }
    },
    async getAllRoleList() {
      const { code, data } = await businessStatusRoleList();
      if (code == 200) {
        this.roleList = data.map((item) => {
          item.value = item.role_id;
          item.label = item.role_name;
          return item;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.derive {
  margin-bottom: 10px;
}
</style>