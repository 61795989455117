<template>
  <div>
    <el-dialog
      :title="title"
      width="520px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="businessAdminModal"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        ref="submitForm"
        size="small"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="用户名：" prop="username">
          <el-input
            v-model="form.username"
            clearable
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input
            maxlength="11"
            v-model="form.mobile"
            clearable
            placeholder="请输入手机后"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="password" v-if="type != 'edit'">
          <el-input
            v-model="form.password"
            clearable
            placeholder="请输入密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="状态：" prop="accountStatus" v-if="type == 'edit'">
          <el-radio-group v-model="form.accountStatus">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="2">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="角色：" prop="appointRoleIdList">
          <el-checkbox-group
            v-model="form.appointRoleIdList"
            @change="onRoleIdChange"
          >
            <el-checkbox
              v-for="item in allRoleList"
              :key="item.role_id"
              :label="item"
              name="type"
              >{{ item.role_name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="一级机构：" v-if="showSenior" prop="seniorId">
          <el-select
            v-model="form.seniorId"
            style="width: 100%"
            :remote-method="seniorRemoteMethod"
            :loading="seniorLoading"
            placeholder="请输入关键词"
            @change="onSeniorChange"
            @clear="onSeniorClear"
            clearable
            filterable
            remote
          >
            <el-option
              v-for="item in seniorList"
              :key="item.senior_id"
              :label="item.senior_name"
              :value="item.senior_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="主体名称：" v-if="showEntity" prop="entityId">
          <el-select
            v-model="form.entityId"
            :remote-method="entityRemoteMethod"
            :loading="entityLoading"
            placeholder="请输入关键词"
            @change="onEntityChange"
            @clear="onEntityClear"
            clearable
            filterable
            remote
            style="width: 100%"
          >
            <el-option
              v-for="item in entityList"
              :key="item.entity_id"
              :label="item.entity_name"
              :value="item.entity_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商家名称：" v-if="showBusiness" prop="businessId">
          <el-select
            v-model="form.businessId"
            :remote-method="businessRemoteMethod"
            :loading="businessLoading"
            placeholder="请输入关键词"
            @change="onBusinessChange"
            @clear="onBusinessClear"
            clearable
            filterable
            remote
            style="width: 100%"
          >
            <el-option
              v-for="item in businessList"
              :key="item.business_id"
              :label="item.full_name"
              :value="item.business_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm('submitForm')" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { selectBusiness, selectSenior, selectEntity } from "@/api/selectfun";
import {
  businessStatusRoleList,
  createBusinessAdmin,
  editBusinessAdmin,
} from "@/api/businessManage.js";
import { JSEncrypt } from "jsencrypt";
export default {
  name: "businessAdminModal",

  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    };
    return {
      title: "新增账号",
      dialogFormVisible: false,
      editManagerId: "",
      form: {
        password: "",
        appointRoleIdList: [],
        businessId: "",
        seniorId: "",
        entityId: "",
        accountStatus: 1,
      },
      rules: {
        username: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入正确的手机号", trigger: "blur" },
          {
            type: "number",
            validator: checkPhone,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        appointRoleIdList: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个角色",
            trigger: "change",
          },
        ],
        businessId: [
          { required: true, message: "请选择商家", trigger: "change" },
        ],
        seniorId: [
          { required: true, message: "请选择一级机构", trigger: "change" },
        ],
        entityId: [
          { required: true, message: "请选择主体", trigger: "change" },
        ],
        accountStatus: [
          { required: true, message: "请选择账号状态", trigger: "change" },
        ],
      },
      businessList: [],
      businessLoading:false,
      seniorList: [],
      seniorLoading:false,
      entityList: [],
      entityLoading:false,
      allRoleList: [],
      allRoleId: [],
      showBusiness: false,
      showSenior: false,
      showEntity: false,
      type: "add",
    };
  },

  mounted() {
    this.businessStatusRoleList();
    // this.getBusiness();
    // this.getSenior();
    // this.getEntity();
  },

  methods: {
    async isShow(type, val) {
      this.dialogFormVisible = true;
      this.type = type;
      if (type == "edit") {
        this.title = "修改账号";
        this.editManagerId = val.id;
        var role_id = val.role_id.split(",");
        var roleList = [];
        var roleTypeList = [];
        role_id.map((item) => {
          this.allRoleList.map((vals) => {
            if (item == vals.role_id) {
              roleList.push(vals);
              roleTypeList.push(vals.role_type);
            }
          });
        });
        this.screenRoleType(roleTypeList);
        this.seniorRemoteMethod(val.senior_name)
        this.entityRemoteMethod(val.entity_name)
        this.businessRemoteMethod(val.business_name)
        this.form = {
          editManagerId: val.id,
          appointRoleIdList: roleList,
          businessId: val.business_id || "",
          seniorId: val.senior_id || "",
          entityId: val.entity_id || "",
          accountStatus: val.status,
          mobile: val.mobile,
          username: val.username,
        };
      } else {
        this.title = "新增账号";
        this.editManagerId = "";
      }
    },
    confirm(submitForm) {
      this.$refs[submitForm].validate(async (valid) => {
        if (valid) {
          this.allRoleId = [];
          this.form.appointRoleIdList.map((item) => {
            this.allRoleId.push(item.role_id);
          });
          const params = {
            ...this.form,
            password: this.passwordEncryption(this.form.password),
            appointRoleId: this.allRoleId.join(","),
          };
          let res;
          this.editManagerId
            ? (res = await editBusinessAdmin(params))
            : (res = await createBusinessAdmin(params));
          if (res.code) {
            this.$message.success("操作成功");
            this.close();
            this.$parent.initData();
          }
        } else {
          return false;
        }
      });
    },
    close() {
      this.dialogFormVisible = false;
      this.form = {
        password: "",
        appointRoleIdList: [],
        businessId: "",
        seniorId: "",
        entityId: "",
        accountStatus: 1,
      };
      (this.showBusiness = false),
        (this.showSenior = false),
        (this.showEntity = false),
        this.$refs["submitForm"].resetFields();
    },
    onRoleIdChange(item) {
      this.allRoleId = [];
      var roleTypeList = [];
      item.map((val) => {
        roleTypeList.push(val.role_type);
        this.allRoleId.push(val.id);
      });
      this.screenRoleType(roleTypeList);
    },
    screenRoleType(roleTypeList) {
      var allRoleType = Array.from(new Set(roleTypeList));
      if (allRoleType.indexOf(1) != -1) {
        this.showBusiness = true;
      } else {
        this.showBusiness = false;
        this.form.businessId = "";
        this.$nextTick(() => {
          this.$refs["submitForm"].clearValidate(["businessId"]);
        });
      }
      if (allRoleType.indexOf(2) != -1) {
        this.showSenior = true;
      } else {
        this.showSenior = false;
        this.form.seniorId = "";
        this.$nextTick(() => {
          this.$refs["submitForm"].clearValidate(["seniorId"]);
        });
      }
      if (allRoleType.indexOf(3) != -1) {
        this.showEntity = true;
      } else {
        this.showEntity = false;
        this.form.entityId = "";
        this.$nextTick(() => {
          this.$refs["submitForm"].clearValidate(["entityId"]);
        });
      }
    },
    async businessStatusRoleList() {
      const { code, data } = await businessStatusRoleList();
      if (code == 200) {
        this.allRoleList = data;
      }
    },
    async getBusiness(val) {
      const param = {
        business_name: val,
      }
      const { code, data } = await selectBusiness(param);
      if (code == 200) {
        this.businessList = data;
      }
    },
    onBusinessChange(val) {
      this.form.businessId = val;
    },
    onBusinessClear() {
      this.form.businessId = "";
    },
    businessRemoteMethod(query) {
      if (query != "") {
        this.businessLoading = true;
        setTimeout(() => {
          this.businessLoading = false;
          this.getBusiness(query);
        }, 200);
      } else {
        this.businessList = [];
      }
    },
    async getSenior(val) {
      const param = {
        senior_name: val,
      }
      const { code, data } = await selectSenior(param);
      if (code == 200) {
        this.seniorList = data;
      }
    },
    onSeniorChange(val) {
      this.form.seniorId = val;
    },
    onSeniorClear() {
      this.form.seniorId = "";
    },
    seniorRemoteMethod(query) {
      if (query != "") {
        this.seniorLoading = true;
        setTimeout(() => {
          this.seniorLoading = false;
          this.getSenior(query);
        }, 200);
      } else {
        this.seniorList = [];
      }
    },
    async getEntity(val) {
      const param = {
        entity_name: val,
      }
      const { code, data } = await selectEntity(param);
      if (code == 200) {
        this.entityList = data;
      }
    },
    onEntityChange(val) {
      this.form.entityId = val;
    },
    onEntityClear() {
      this.form.entityId = "";
    },
    entityRemoteMethod(query) {
      if (query != "") {
        this.entityLoading = true;
        setTimeout(() => {
          this.entityLoading = false;
          this.getEntity(query);
        }, 200);
      } else {
        this.entityList = [];
      }
    },
    passwordEncryption(passwordUser) {
      let publicKey =
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDiHQgcc55lLY/s6cfF+teZ8chBskyTMWwo1ai6nWMxjZh1EijuaenXbJu9DsJy579DGlP2Y8+0cAfCZLYXbp8ab1XANbH4ubqkq5eV9drBbCAIvDZeCS65MaHg3zln/AbTIDxfVEbGcI/g3kRQF7X0Pp7YfABZ6o04SqRfaV8npwIDAQAB";
      let encryptor = new JSEncrypt();
      encryptor.setPublicKey(publicKey);
      let passwordEncryp = encryptor.encrypt(passwordUser);
      return passwordEncryp;
    },
    handleClose(done) {
      this.close();
      done();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .businessAdminModal {
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title {
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
}
</style>